import React from 'react';
import Chakra from '../Assets/chakra.png';
import PVR from '../Assets/pvr.png';
import pilupu from '../Assets/pilupu.png';
import jb from '../Assets/jb.png';
export default function Clients() {
  return (
    <div>
      <div className=' flex justify-center mt-10'>
       <div className=" w-4 h-4 border-t-4 border-l-4 border-customBlue"></div>
      <span className=' text-4xl font-semibold text-customBlue '>Our Clients</span>
      <div className="mt-6 w-4 h-4 border-b-4 border-r-4 border-customBlue "></div>
      </div>
      <span className='flex justify-center mt-10 text-md md:text-xl' >We Don't Just Build Softwares, We Build Your Business.</span>
      <div className='flex justify-center mt-10 mb-10'>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-10">
          <div className="flex justify-center items-center">
            <img src={PVR} alt="PVR" className="w-40 h-40 object-contain" />
          </div>
          <div className="flex justify-center items-center">
            <img src={pilupu} alt="Pilupu" className="w-40 h-40 object-contain" />
          </div>
          <div className="flex justify-center items-center">
            <img src={jb} alt="JB" className="w-40 h-40 object-contain" />
          </div>
          <div className="flex justify-center items-center">
            <img src={Chakra} alt="Client" className="w-40 h-40 object-contain" />
          </div>
        </div>
      </div>
    </div>
  )
}
