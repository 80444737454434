import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin,faXTwitter,faYoutube } from '@fortawesome/free-brands-svg-icons';



function Footer1() {

    return (
        <div className='bg-blue-950 w-full h-full '>
            <div className='  md:grid grid-cols-3  lg:px-56 py-16  justify-center md:justify-between ml-14 md:ml-0 md:px-6 '>
                <div className='text-between'>
                    <div className='text-white font-bold '>
                        ABOUT US
                    </div>
                    <ul className="text-white underline-offset-2 space-y-4  mt-2">
                        <li className="flex items-center ">
                            <span>{'>'}</span>
                            <a href='#' className="ml-2 hover:text-blue-400 hover:underline  underline-offset-4 border-b-2 py-2 w-40" >Home</a>

                        </li>
                        <li className="flex items-center ">
                            <span>{'>'}</span>
                            <a href='#' className="ml-2 hover:text-blue-400 hover:underline border-b-2 py-2 w-40">About Us</a>
                        </li>
                        <li className="flex items-center ">
                            <span>{'>'}</span>
                            <a href='#' className="ml-2 hover:text-blue-400 hover:underline border-b-2 py-2 w-40">Our Services</a>
                        </li>
                        <li className="flex items-center ">
                            <span>{'>'}</span>
                            <a href='#' className="ml-2 hover:text-blue-400 hover:underline border-b-2 py-2 w-40">Terms & Conditions</a>
                        </li>
                        <li className="flex items-center ">
                            <span>{'>'}</span>
                            <a href='#' className="ml-2 hover:text-blue-400 hover:underline border-b-2 py-2 w-40">Privacy & Policy</a>
                        </li>
                    </ul>
                </div>
                <div className='md:mt-0 mt-4'>
                    <div className='text-white font-bold'>
                        USEFUL LINKS
                    </div>
                    <ul className="text-white underline-offset-2 space-y-4 mt-2">
                        <li className="flex items-center ">
                            <span>{'>'}</span>
                            <a href='#' className="ml-2 hover:text-blue-400 hover:underline border-b-2 py-2 w-40">Portfolio</a>
                        </li>
                        <li className="flex items-center ">
                            <span>{'>'}</span>
                            <a href='#' className="ml-2 hover:text-blue-400 hover:underline border-b-2 py-2 w-40">Team</a>
                        </li>
                        <li className="flex items-center ">
                            <span>{'>'}</span>
                            <a href='#' className="ml-2 hover:text-blue-400 hover:underline border-b-2 py-2 w-40">Career</a>
                        </li>
                        <li className="flex items-center ">
                            <span>{'>'}</span>
                            <a href='#' className="ml-2 hover:text-blue-400 hover:underline border-b-2 py-2 w-40">Contact</a>
                        </li>
                        <li className="flex items-center ">
                            <span>{'>'}</span>
                            <a href='#' className="ml-2 hover:text-blue-400 hover:underline border-b-2 py-2 w-40">FAQ</a>
                        </li>
                    </ul>

                </div>
                <div className='md:mt-0 mt-4'>
                    <div className='text-white font-bold'>
                        CONTACT US
                    </div>
                    <div className='mt-2'>
                        <p className='text-white'>
                        4th Floor, Alcazar Plaza, Road Number 1,<br/>
                        Banjara Hills, Hyderabad - 500038<br/>
                            <span className='font-bold'>
                               Phone:
                               </span>
                            <span>
                                +91 987654321 <br></br>
                            </span>
                            <span className='font-bold'>
                               Email: 
                            </span>
                            <span>
                                chakragroup.hyd@gmail.com
                            </span>
                        </p>
                    </div>
                    <div>
                        <div>
                        <div className='flex space-x-6 text-center'>
                            <a href="https://www.facebook.com/search/top?q=chakra%20infotainment" >
                        <FontAwesomeIcon icon={faFacebook} size='2x' className='mt-4 rounded-full ' style={{ color: '#0000FF' }} />
                        </a>
                        <a href='https://www.instagram.com/chakrainfotainment'>
                        <FontAwesomeIcon icon={faInstagram} size='2x' className='mt-4 rounded-full ' style={{ color: '#E1306C' }} />
                        </a>
                       <a href="https://www.linkedin.com/">
                        <FontAwesomeIcon icon={faLinkedin} size='2x' className='mt-4 rounded-full ' style={{ color: '#0077B5' }} />
                        </a>
                       <a href="https://twitter.com/Chakrainfotain?t=m5zhh141lZSyJlREI6aZNA&s=08">
                       <FontAwesomeIcon icon={faXTwitter} size='2x' className='mt-4 rounded-md box-border h-7 w-7 bg-white ' style={{ color: '#000000' }} />
                        </a>
                        <a href="https://www.youtube.com/@chakrainfotainment6507" >
                        <FontAwesomeIcon icon={faYoutube } size='2x' className='mt-4 rounded-full ' style={{ color: '#FF0000' }} />
                        </a>
                        </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Footer1