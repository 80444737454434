import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseTsunami, faLaptop, faMagic } from '@fortawesome/free-solid-svg-icons';
import { faAndroid, faCreativeCommons, faUps } from '@fortawesome/free-brands-svg-icons'; // Importing faAndroid from free-brands-svg-icons



const Projects = ({ ProjectRef }) => {
  return (
    <div className=" bg-white " style={{ Height: "70vh" }} ref={ProjectRef}>
      <div className=" mx-auto py-5">


        <div className="flex justify-center ">
          <h1 className="h-4 w-4 border-l-4 border-t-4 border-customBlue"></h1>
          <h1 className="text-4xl text-customBlue">Our Projects</h1>
          <h1 className="mt-7 h-4 w-4 border-b-4 border-r-4 border-customBlue"></h1>
        </div>

        <p className="text-black text-semibold text-center  text-xl mt-5">
          The Objective Of IT Company Is To Enable A Large Number Of Youth To Take Up<br />
          Industry-Relevant Skill Training That Will Help Them<br />
          In Securing A Better Livelihood.
        </p>

        <div className="grid grid-cols-1 md:p-24 px-5 md:grid-cols-3 gap-10 md:-mt-10">
          <div className="bg-white p-6 text-center shadow-sm rounded-xl border border-gray-200 bg-gradient-to-br hover:from-green-100 hover:via-lightblue-500 hover:to-pink-300">




            <h6 className="text-xl font-semibold mb-2">Joy Bharath</h6>
            <p className="text-gray-700">
              At Joy Bharath, we take great pride in our uncompromising integrity in customer engagement and quality assurance, and we have made it our core mission to provide real-estate development,management, and Entertainment services of the highest calibre.
            </p>
          </div>
          <div className="bg-white text-center p-8 shadow-sm rounded-2xl border  border-gray-200 bg-gradient-to-br hover:from-green-100 hover:via-lightblue-500 hover:to-pink-300">

            <h6 className="text-xl font-semibold mb-2">Chakra Group</h6>
            <p className="text-gray-700">
              At Chakra Group, we take great pride in our uncompromising integrity in customer engagement and quality assurance, and we have made it our core mission to provide real-estate development,management, and Entertainment services of the highest calibre.
            </p>
          </div>
          <div className="bg-white text-center p-8 shadow-md rounded-2xl border border-gray-200 bg-gradient-to-br hover:from-green-100 hover:via-lightblue-500 hover:to-pink-300">

            <h6 className="text-xl font-semibold mb-2">Chakra Infotainment</h6>
            <p className="text-gray-700">
              Chakra Infotainment LLP (“Chakra”) is a media and entertainment business, engaged in production, acquisition, aggregation and distribution of audio and video content for digital, satellite and theatrical platforms predominantly in Telugu language
            </p>
          </div>
          <div className="bg-white text-center p-8 shadow-md rounded-2xl border border-gray-200 bg-gradient-to-br hover:from-green-100 hover:via-lightblue-500 hover:to-pink-300">

            <h6 className="text-xl font-semibold mb-2">PVR Banquet Hall</h6>
            <p className="text-gray-700">
            Developed a user-friendly website for PVR Banquet Hall, showcasing its services and allowing direct customer contact with the owner, eliminating the need for brokers. The site features a responsive design, easy navigation, and seamless booking options.
            </p>
          </div>
          <div className="bg-white text-center p-8 shadow-md rounded-2xl border border-gray-200 bg-gradient-to-br hover:from-green-100 hover:via-lightblue-500 hover:to-pink-300">

            <h6 className="text-xl font-semibold mb-2">Pilupu</h6>
            <p className="text-gray-700">
            Pilupu application offers a seamless experience for creating and sending invitations to imported contacts from their mobile device across various categories.  Whether you're planning a party, event, or gathering, Pilupu simplifies the process of inviting your guests with just a few taps.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;