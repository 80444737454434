import React from 'react'
import BackgroundImg from '../Assets/img1.jpg';

function Background() {
  return (
    <div className="relative h-screen">
      {/* Background image */}
      <img
        src={BackgroundImg}
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover sm:w-full "
      />
      
      {/* Text in the middle */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
        <div className="text-2xl md:text-6xl font-semibold mb-4">Bring Your Business Online</div>
        <div className="text-xl md:text-4xl">With IT Company Services</div>
      </div>
    </div>
  )
}

export default Background