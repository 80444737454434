import React from 'react'

function Footer2() {
  return (
    <>
    <div className='bg-black h-14 ' >
        <div className='text-white '>
       <span className='flex  text-center justify-center'>  Copyright © 2021 IT Company Website. All Rights Reserved.</span>
        </div>
      
    </div>
    </>
  )
}

export default Footer2