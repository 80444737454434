import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faGift, faUsers, faAward } from '@fortawesome/free-solid-svg-icons';
import img2 from '../Assets/img2.jpg'
export default function Numbers() {
  return (
    <div className="bg-cover bg-fixed bg-no-repeat bg-center text-white py-10 px-5 md:py-20 md:px-10" style={{ backgroundImage: `url(${img2})` }}>
      <div className='grid  grid-cols-2 gap-4 sm:grid-cols-4  md:gap-3 md:px-20 justify-center'>
        <div className="text-center border-b-2 sm:border-b-0 ">
          <div><FontAwesomeIcon icon={faClock} className="text-4xl" /></div>
          <div className='text-5xl mt-3 mb-3'>13500</div>
          <h3 className='mb-3'>Working Hours</h3>
        </div>
        <div className="text-center border-b-2 sm:border-b-0">
          <div className=''> <FontAwesomeIcon icon={faGift} className="text-4xl" /></div>
          <div className='text-5xl mt-3 mb-3'>5</div>
          <h3>Completed Projects</h3>
        </div>
        <div className="text-center">
          <div><FontAwesomeIcon icon={faUsers} className="text-4xl" /></div>
          <div className='text-5xl mt-3 mb-3'>480</div>
          <h3>Happy Clients</h3>
        </div>
        <div className="text-center">
          <div><FontAwesomeIcon icon={faAward} className="text-4xl" />
          </div>
          <div className='text-5xl mt-3 mb-3'>120</div>
          <h3>Awards Received</h3>
        </div>
      </div>
    </div>
  );
}
